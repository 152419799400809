import React, { useRef, useState } from "react"
import { navigate } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import InnerHeader from "../../components/global/page-partials/inner-header-1/InnerHeader"
import * as sponsorshipRequestsStyles from "./sponsorship-requests.module.scss"

const SponsorshipRequests = ({ pageContext, location }) => {
  const formRef = useRef(null)
  const [formState, setFormState] = useState({
    requestType: "",
    name: "",
    email: "",
    organization: "",
    phone: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    websiteURL: "",
    brandRequested: "",
    donationType: "",
    fundraiserEvent: "",
    organizationFit: "",
    additionalInfoFile: null,
  })

  const [errors, setErrors] = useState({
    requestType: "",
    name: "",
    email: "",
    organization: "",
    phone: "",
    addressLine1: "",
    city: "",
    zip: "",
    country: "",
    websiteURL: "",
    brandRequested: "",
    fundraiserEvent: "",
    organizationFit: "",
    additionalInfoFile: "",
  })

  const handleChange = event => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    })
  }

  const handleFileChange = event => {
    setFormState({
      ...formState,
      additionalInfoFile: event.target.files[0],
    })
  }

  const handleSubmit = event => {
    event.preventDefault()

    // Define validation functions
    const isRequired = value => (value ? "" : "This field is required.")
    const isValidEmail = value =>
      /\S+@\S+\.\S+/.test(value) ? "" : "Please provide a valid email address."
    const isValidUrl = value =>
      value === "" || /^https?:\/\/[^ ]+$/i.test(value)
        ? ""
        : "Please provide a valid URL."
    const isValidFileType = value =>
      value === null ||
      ["application/pdf", "image/jpeg", "image/png"].includes(value.type)
        ? ""
        : "Invalid file type. Please upload a PDF or JPEG/PNG image."
    const isFileSizeOk = value =>
      value === null || value.size <= 4000000
        ? ""
        : "File size is too large. Please upload a file of size up to 4 MB."

    // Define fields to validate, these must be in order of appearance in the form for the first error field to be focused on correctly
    const fieldsToValidate = [
      { field: "requestType", validate: isRequired },
      { field: "name", validate: isRequired },
      { field: "email", validate: [isRequired, isValidEmail] },
      { field: "organization", validate: isRequired },
      { field: "phone", validate: isRequired },
      { field: "addressLine1", validate: isRequired },
      { field: "city", validate: isRequired },
      { field: "zip", validate: isRequired },
      { field: "country", validate: isRequired },
      { field: "websiteURL", validate: isValidUrl },
      { field: "brandRequested", validate: isRequired },
      { field: "fundraiserEvent", validate: isRequired },
      { field: "organizationFit", validate: isRequired },
      {
        field: "additionalInfoFile",
        validate: [isValidFileType, isFileSizeOk],
      },
    ]

    // Perform validation
    let newErrors = {}
    fieldsToValidate.forEach(({ field, validate }) => {
      const value = formState[field]
      const validators = Array.isArray(validate) ? validate : [validate]
      validators.forEach(validator => {
        const error = validator(value)
        if (error) {
          newErrors[field] = error
        }
      })
    })

    if (Object.keys(newErrors).length > 0) {
      // Update error state with new errors
      setErrors(newErrors)

      // Find the first field with an error and focus on it
      const firstErrorField = document.querySelector(
        `#${Object.keys(newErrors)[0]}`
      )
      if (firstErrorField) {
        firstErrorField.focus()
      }
    } else {
      // If no errors, perform form submission
      const form = event.target
      const formData = new FormData(form)
      fetch("/form-confirmation/sponsorship-request/", {
        method: "POST",
        body: formData,
      })
        .then(navigate("/form-confirmation/sponsorship-request/"))
        .catch(error => alert(error))
    }
  }

  return (
    <>
      <Layout>
        <Seo title="Sponsorship Requests" />
        <InnerHeader
          pageTitle="Sponsorship Requests"
          pageContext={pageContext}
          location={location}
        />
        <div
          className={`inner-container-alt inner-y-padding ${sponsorshipRequestsStyles.contentWrapper}`}
        >
          <h2>Sponsorship & Media Request Form</h2>
          <p
            style={{
              maxWidth: "50rem",
              marginBottom: "3rem",
              lineHeight: "1.3",
            }}
            data-typesense-field="description"
          >
            Thank you for your interest in Rocky Brands&#8482; Advertising,
            Donation, and Sponsorship opportunities. To best answer your inquiry
            and ensure the appropriate information is obtained, please identify
            the request type in the first field of the form below. Please
            complete the appropriate form with as much information as possible.
            Requests deemed incomplete will not be evaluated.
            <br />
            <br />
            If you are a country music artist looking to form a partnership with
            Durango&reg;, please fill out the New Country Music Artist Form.
          </p>
          <div>
            <a
              href="https://www.durangoboots.com/artist/"
              target="_blank"
              rel="noopener noreferrer"
              title="New Country Music Artist Partnership Form"
              className={`biggerLink`}
            >
              New Country Music Artist Form ▸
            </a>
            {/* form start */}
            <form
              name="sponsorship-requests"
              method="post"
              data-netlify="true"
              netlify-honeypot="special-request"
              encType="multipart/form-data"
              ref={formRef}
              onSubmit={handleSubmit}
            >
              <small>
                <strong>
                  Note: Each request submitted does not guarantee a partnership
                  or response. We will contact you if there is interest in your
                  submission.
                  <br /> <br />
                </strong>
              </small>
              <small>
                <span
                  className="text-danger"
                  style={{ fontSize: `1.1rem`, fontWeight: `bold` }}
                >
                  *
                </span>{" "}
                indicates required field
              </small>
              <input class="hidden" name="special-request" />
              <input
                type="hidden"
                name="form-name"
                value="sponsorship-requests"
              />
              <div className="form-group">
                <label className="required" htmlFor="requestType">
                  Select Type of Request:{" "}
                </label>
                <select
                  name="requestType"
                  id="requestType"
                  className="form-control"
                  value={formState.requestType}
                  onChange={handleChange}
                  aria-describedby={
                    errors.requestType ? "requestType-error" : null
                  }
                >
                  <option value="">Select One</option>
                  <option value="advertising">Advertising</option>
                  <option value="donation">Donation</option>
                  <option value="sponsorship">Sponsorship</option>
                </select>
                {errors.requestType && (
                  <small id="requestType-error" className="text-danger">
                    {errors.requestType}
                  </small>
                )}
              </div>
              {/* name, email, phone, address, city, state, zip, country */}
              <div className="form-group">
                <label htmlFor="name" className="required">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={formState.name}
                  onChange={handleChange}
                  autoComplete="name"
                  className="form-control"
                  placeholder="Name"
                  aria-describedby={errors.name ? "name-error" : null}
                />
                {errors.name && (
                  <small id="name-error" className="text-danger">
                    {errors.name}
                  </small>
                )}
              </div>
              <div className="form-group">
                <label className="required" htmlFor="email">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={formState.email}
                  onChange={handleChange}
                  autoComplete="email"
                  className="form-control"
                  placeholder="Email"
                  aria-describedby={errors.email ? "email-error" : null}
                />

                {errors.email ? (
                  <small id="email-error" className="text-danger">
                    {errors.email} (ie. info@example.com)
                  </small>
                ) : (
                  <small>ie. info@example.com</small>
                )}
              </div>
              {/* organization */}
              <div className="form-group">
                <label className="required" htmlFor="organization">
                  Organization
                </label>
                <input
                  type="text"
                  name="organization"
                  id="organization"
                  autoComplete="organization"
                  className="form-control"
                  placeholder="Organization"
                  value={formState.organization}
                  onChange={handleChange}
                  aria-describedby={
                    errors.organization ? "organization-error" : null
                  }
                />
                {errors.organization && (
                  <small id="organization-error" className="text-danger">
                    {errors.organization}
                  </small>
                )}
              </div>
              <div className="form-group">
                <label className="required" htmlFor="phone">
                  Phone
                </label>
                <input
                  type="tel"
                  name="phone"
                  id="phone"
                  autoComplete="tel"
                  className="form-control"
                  placeholder="Phone"
                  value={formState.phone}
                  onChange={handleChange}
                  aria-describedby={errors.phone ? "phone-error" : null}
                />
                {errors.phone && (
                  <small id="phone-error" className="text-danger">
                    {errors.phone}
                  </small>
                )}
              </div>
              <div className="form-group">
                <label className="required" htmlFor="addressLine1">
                  Address Line 1
                </label>
                <input
                  type="text"
                  name="addressLine1"
                  id="addressLine1"
                  autoComplete="address-line1"
                  className="form-control"
                  placeholder="Address Line 1"
                  value={formState.addressLine1}
                  onChange={handleChange}
                  aria-describedby={
                    errors.addressLine1 ? "addressLine1-error" : null
                  }
                />
                {errors.addressLine1 && (
                  <small id="addressLine1-error" className="text-danger">
                    {errors.addressLine1}
                  </small>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="addressLine2">Address Line 2</label>
                <input
                  type="text"
                  name="addressLine2"
                  id="addressLine2"
                  autoComplete="address-line2"
                  className="form-control"
                  placeholder="Address Line 2"
                  value={formState.addressLine2}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group">
                <label className="required" htmlFor="city">
                  City
                </label>
                <input
                  type="text"
                  name="city"
                  id="city"
                  autoComplete="city"
                  className="form-control"
                  placeholder="City"
                  value={formState.city}
                  onChange={handleChange}
                  aria-describedby={errors.city ? "city-error" : null}
                />
                {errors.city && (
                  <small id="city-error" className="text-danger">
                    {errors.city}
                  </small>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="state">State/Province</label>
                <input
                  type="text"
                  name="state"
                  id="state"
                  autoComplete="state"
                  className="form-control"
                  placeholder="State/Province"
                  value={formState.state}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label className="required" htmlFor="zip">
                  Zip
                </label>
                <input
                  type="text"
                  name="zip"
                  id="zip"
                  autoComplete="zip"
                  className="form-control"
                  placeholder="Zip"
                  value={formState.zip}
                  onChange={handleChange}
                  aria-describedby={errors.zip ? "zip-error" : null}
                />
                {errors.zip && (
                  <small id="zip-error" className="text-danger">
                    {errors.zip}
                  </small>
                )}
              </div>
              <div className="form-group">
                <label className="required" htmlFor="country">
                  Country
                </label>
                <input
                  type="text"
                  name="country"
                  id="country"
                  autoComplete="country"
                  className="form-control"
                  placeholder="Country"
                  value={formState.country}
                  onChange={handleChange}
                  aria-describedby={errors.country ? "country-error" : null}
                />
                {errors.country && (
                  <small id="country-error" className="text-danger">
                    {errors.country}
                  </small>
                )}
              </div>
              {/* website url */}
              <div className="form-group">
                <label htmlFor="websiteURL">Website URL</label>
                <input
                  type="text"
                  name="websiteURL"
                  id="websiteURL"
                  className="form-control"
                  placeholder="Website URL"
                  value={formState.websiteURL}
                  onChange={handleChange}
                  aria-describedby={
                    errors.websiteURL ? "websiteURL-error" : null
                  }
                />

                {errors.websiteURL ? (
                  <small id="websiteURL-error" className="text-danger">
                    {errors.websiteURL} (ie. https://www.rockybrands.com)
                  </small>
                ) : (
                  <small>ie. https://www.rockybrands.com</small>
                )}
              </div>
              {/* Select box, is there a specific brand you are requesting?  */}
              <div className="form-group">
                <label className="required" htmlFor="brandRequested">
                  Is there a specific brand you are requesting?{" "}
                </label>
                <select
                  name="brandRequested"
                  id="brandRequested"
                  className="form-control"
                  value={formState.brandRequested}
                  onChange={handleChange}
                  aria-describedby={
                    errors.brandRequested ? "brandRequested-error" : null
                  }
                >
                  <option value="">Select One</option>
                  <option value="no-specific-brand">No Specific Brand</option>
                  <option value="rocky-boots">Rocky Boots</option>
                  <option value="durango">Durango</option>
                  <option value="georgia-boot">Georgia Boot</option>
                  <option value="muck-boot-co">Muck Boot Co</option>
                  <option value="xtratuf">XTRATUF</option>
                  <option value="ranger">Ranger</option>
                  <option value="lehigh-customfit">Lehigh CustomFit</option>
                  <option value="lehigh-outfitters">Lehigh Outfitters</option>
                  <option value="lehigh-safety-shoes">
                    Lehigh Safety Shoes
                  </option>
                  <option value="shoe-angel">Shoe Angel</option>
                  <option value="slipgrips">SlipGrips</option>
                </select>
                {errors.brandRequested && (
                  <small id="brandRequested-error" className="text-danger">
                    {errors.brandRequested}
                  </small>
                )}
              </div>
              {/* What type of donation are you seeking?  */}
              <div className="form-group">
                <label htmlFor="donationType">
                  What type of donation are you seeking?
                </label>
                <input
                  type="text"
                  name="donationType"
                  id="donationType"
                  className="form-control"
                  placeholder="Donation Type"
                  value={formState.donationType}
                  onChange={handleChange}
                />
              </div>
              {/* Is this donation for a fundraiser event? */}
              <div className="form-group">
                <label className="required" htmlFor="fundraiserEvent">
                  Is this donation for a fundraiser event?{" "}
                </label>
                <select
                  name="fundraiserEvent"
                  id="fundraiserEvent"
                  className="form-control"
                  value={formState.fundraiserEvent}
                  onChange={handleChange}
                  aria-describedby={
                    errors.fundraiserEvent ? "fundraiserEvent-error" : null
                  }
                >
                  <option value="">Select One</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
                {errors.fundraiserEvent && (
                  <small id="fundraiserEvent-error" className="text-danger">
                    {errors.fundraiserEvent}
                  </small>
                )}
              </div>
              {/* Why is your organization or opportunity a fit for Rocky Brands */}
              <div className="form-group">
                <label className="required" htmlFor="organizationFit">
                  Why is your organization or opportunity a fit for Rocky
                  Brands&#8482;?
                </label>
                <textarea
                  name="organizationFit"
                  id="organizationFit"
                  className="form-control"
                  value={formState.organizationFit}
                  onChange={handleChange}
                  aria-describedby={
                    errors.organizationFit ? "organizationFit-error" : null
                  }
                />
                {errors.organizationFit && (
                  <small id="organizationFit-error" className="text-danger">
                    {errors.organizationFit}
                  </small>
                )}
              </div>
              {/* Upload field for additional files */}
              <div className="form-group">
                <label htmlFor="additionalInfoFile">
                  Please upload any additional information about your request
                  here:
                </label>
                <input
                  type="file"
                  name="additionalInfoFile"
                  id="additionalInfoFile"
                  className="file-upload"
                  onChange={handleFileChange}
                  aria-describedby={
                    errors.additionalInfoFile
                      ? "additionalInfoFile-error"
                      : null
                  }
                />
                {errors.additionalInfoFile && (
                  <small id="additionalInfoFile-error" className="text-danger">
                    {errors.additionalInfoFile}
                  </small>
                )}
              </div>
              <p>
                Your request will be delivered to the appropriate marketing
                manager for evaluation. Due to the volume of inquiries received,
                Rocky Brands&#8482; cannot respond to every request. If we are
                interested in your sponsorship or need additional information,
                we will contact you.
              </p>

              {/* submit button */}
              <div>
                <input className="CTAblack" type="submit" value="Submit" />
              </div>
            </form>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default SponsorshipRequests

//head element
export const Head = () => (
  <>
    <title data-typesense-field="title">Sponsorship Requests</title>
    <meta
      name="description"
      content="
        Rocky Brands&#8482; is a world leader in rugged outdoor and occupational footwear. We have a 
        history of creating innovative footwear and we are looking for partners who share our passion 
        for excellence. If you are interested in becoming a Rocky Brands&#8482; dealer, please fill out the form below and a Rocky Brands&#8482; representative will contact you if we are interested in your submission.
      "
    />
  </>
)
